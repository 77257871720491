<template>
  <form class="vehicle-form-inner" ref="form" @submit.prevent="submit">
    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.PICKUP_LOCATION')}`"
        :placeholder="`${$t('VEHICLES.PICKUP_LOCATION')}`"
      >
        <addresses-selector
          :address="vehicle.pickup_location"
          @addressInputChangeChanged="
            (values) => {
              vehicle.pickup_location = values;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.pickup_location" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.auction_type"
        :label="`${$t('AUCTIONS.AUCTION_TYPE')}`"
        :disable="disable"
        :required="true"
        :placeholder="`${$t('AUCTIONS.AUCTION_TYPE')}`"
      >
        <auction-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.auction_type"
          @auctionTypeChanged="
            (type) => {
              vehicle.auction_type = type;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.auction_type" />
    </div>

    <div
      v-if="[AUCTION_TYPE_EXPRESS].includes(vehicle.auction_type)"
      class="inspection-form-group width-1_2"
    >
      <base-input
        :label="`${$t('COMMON.EVENT')}`"
        :placeholder="`${$t('COMMON.EVENT')}`"
      >
        <event-selector
          :event="vehicle.event?.id"
          required
          @eventChanged="
            (eventId) => {
              vehicle.event = {
                id: eventId,
                type: 'events',
              };
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.event_id" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.buy_now_price"
        type="number"
        :label="`${$t('VEHICLES.BUY_NOW_PRICE')}`"
        :placeholder="`${$t('VEHICLES.BUY_NOW_PRICE')}`"
        :disable="disable"
      />

      <validation-error :errors="apiValidationErrors.buy_now_price" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.min_bid_amount"
        type="number"
        :label="`${$t('VEHICLES.MIN_BID_AMOUNT')}`"
        :placeholder="`${$t('VEHICLES.MIN_BID_AMOUNT')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.min_bid_amount" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.reserve_price"
        type="number"
        :label="`${$t('VEHICLES.RESERVE_PRICE')}`"
        :placeholder="`${$t('VEHICLES.RESERVE_PRICE')}`"
        :disable="disable"
        required
      />

      <validation-error :errors="apiValidationErrors.reserve_price" />
    </div>
  </form>
</template>

<script>
import _, { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";
import AuctionTypeSelector from "@/components/AuctionTypeSelector.vue";
import EventSelector from "@/components/EventSelector.vue";
import moment from "moment";
import FlatPicker from "vue-flatpickr-component";
import {
  AUCTION_TYPE_EVALUATION,
  AUCTION_TYPE_TIMED,
  AUCTION_TYPE_EXPRESS,
} from "@/constants/auctions";
import swal from "sweetalert2";

export default {
  name: "step4",
  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    AddressesSelector,
    AuctionTypeSelector,
    EventSelector,
    FlatPicker,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: {
      type: Array,
      default: () => [],
    },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    console.log("props", this.vehicleData);

    let vehicleData = {
      ...cloneDeep(this.vehicleData),
      pickup_location: this.vehicleData.pickup_location ?? {
        city: this.vehicleData.allowedLocations[0].billing_city,
        state: this.vehicleData.allowedLocations[0].billing_state,
        formatted_address: this.vehicleData.allowedLocations[0].address,
      },
    };

    return {
      vehicle: vehicleData,
      AUCTION_TYPE_EVALUATION,
      AUCTION_TYPE_TIMED,
      AUCTION_TYPE_EXPRESS,
    };
  },

  computed: {},

  created() {},

  methods: {
    isFormValid() {
      return this.$refs.form.checkValidity();
    },

    async submit() {
      try {
        await this.$store.dispatch("auctions/list", {
          filter: {
            auction_type: this.vehicle.auction_type,
            status: "ongoing",
          },
          fields: {},
          sort: "-created_at",
          page: {
            size: 1,
          },
        });

        console.log("auctions list", this.$store.getters["auctions/list"]);

        const auctions = this.$store.getters["auctions/list"];

        if (!auctions.length) {
          await swal.fire({
            text: this.$t("VEHICLES.NO_AUCTION_FOUND"),
            customClass: {
              popup: "delete-popup",
            },
            showCancelButton: false,
            confirmButtonClass: "btn base-button",
          });

          return;
        }

        this.$emit("submitted", {
          ...cloneDeep(this.vehicle),
          current_step: 3,
          auction: {
            id: auctions[0].id,
            type: "auctions",
          },
        });
      } catch (error) {
        console.log("error", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = cloneDeep(vehicleData);
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        if (value.auction_type !== AUCTION_TYPE_EXPRESS) {
          this.vehicle.event = null;
        }

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
