<template>
  <el-select
    v-model="eventModel"
    @change="eventChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.EVENT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getEvents"
    :loading="loading"
    :allow-create="allowCreate"
    :required="required"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')" />
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')" />

    <el-option
      v-for="event in events"
      :key="event.id"
      :value="event.id"
      :label="`${event.name} du ${$timeZoneDateFormat(
        event.start_at
      )} au ${$timeZoneDateFormat(event.end_at)}`"
    >
      {{
        `${event.name} du ${$timeZoneDateFormat(
          event.start_at
        )} au ${$timeZoneDateFormat(event.end_at)}`
      }}
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { AUCTION_TYPE_EXPRESS } from "../constants/auctions";

export default {
  name: "event-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    event: {
      type: String,
      default: null,
      description: "Event id",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      eventModel: this.event,
      events: {},
      AUCTION_TYPE_EXPRESS,
    };
  },

  setup() {},

  created() {
    this.getEvents(null, this.event);
  },

  methods: {
    async getEvents(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          filter: {
            expired: 0,
            auction_type: AUCTION_TYPE_EXPRESS,
          },
          fields: { events: "name,start_at,end_at,recurrent,parent_id" },
          sort: "start_at",
        };

        await this.$store.dispatch("events/list", params);
        const eventsArr = await this.$store.getters["events/list"];
        this.events = {};
        eventsArr.forEach((event) => {
          this.events[event.id] = event;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    eventChanged(event) {
      this.$emit("eventChanged", event);
    },
  },

  watch: {
    event(event) {
      if (event) {
        this.eventModel = event;
        if (event !== this.eventModel) {
          //s this.getevents(null, event);
        }
      } else {
        this.eventModel = null;
      }
    },
  },
};
</script>
