<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.DOCUMENTS_STATUS')} (*)`"
        :placeholder="$t('VEHICLES.DOCUMENTS_STATUS')"
        v-model="vehicle.vehicle_documents_status"
      >
        <el-select
          v-model="vehicle.vehicle_documents_status"
          :placeholder="$t('VEHICLES.DOCUMENTS_STATUS')"
          :loading="loading"
          required
        >
          <el-option
            v-for="value in DOCUMENTS_STATUS"
            :key="value"
            :value="value"
            :label="`${$t('VEHICLES.DOCUMENTS_STATUS_' + value)}`"
          />
        </el-select>
      </base-input>

      <validation-error
        :errors="apiValidationErrors.vehicle_documents_status"
      />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ $t("COMMON.SAVE") }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DOCUMENTS_STATUS } from "@/constants/vehicles";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["vehicleData", "formErrors", "loading"],

  data() {
    let vehicleData = {
      ...this.vehicleData,
    };

    return {
      vehicle: vehicleData,
      DOCUMENTS_STATUS,
    };
  },

  created() {},

  mounted() {},

  methods: {
    async handleSubmit() {
      let vehicleData = cloneDeep(this.vehicle);

      this.$emit("vehicleSubmitted", vehicleData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = {
          ...this.vehicle,
          ...cloneDeep(vehicleData),
        };
      }
    },
  },
};
</script>
