var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(
      [_vm.AUCTION_TYPE_EVALUATION, _vm.AUCTION_TYPE_TIMED].includes(
        _vm.vehicle.auction_type
      )
    )?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.AUCTION_END_DATE')} (*)`,"placeholder":_vm.$t('VEHICLES.AUCTION_END_DATE')},model:{value:(_vm.vehicle.auction_end_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_end_date", $$v)},expression:"vehicle.auction_end_date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('VEHICLES.AUCTION_END_DATE'),"config":{
          allowInput: true,
          enableTime: true,
          type: 'datetime',
          locale: _vm.$flatPickrLocale(),
          minDate: 'today',
        }},model:{value:(_vm.vehicle.auction_end_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_end_date", $$v)},expression:"vehicle.auction_end_date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.auction_end_date}})],1):_vm._e(),([_vm.AUCTION_TYPE_EVALUATION].includes(_vm.vehicle.auction_type))?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE')} (*)`,"placeholder":_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE')},model:{value:(_vm.vehicle.sale_confirmation_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "sale_confirmation_date", $$v)},expression:"vehicle.sale_confirmation_date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE'),"config":{
          allowInput: true,
          enableTime: true,
          type: 'datetime',
          locale: _vm.$flatPickrLocale(),
          minDate: _vm.vehicle.auction_end_date,
          disable: [
            function (date) {
              return !_vm.vehicle.auction_end_date;
            },
          ],
        }},model:{value:(_vm.vehicle.sale_confirmation_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "sale_confirmation_date", $$v)},expression:"vehicle.sale_confirmation_date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sale_confirmation_date}})],1):_vm._e(),([_vm.AUCTION_TYPE_EXPRESS].includes(_vm.vehicle.auction_type))?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EVENT')}`,"placeholder":`${_vm.$t('COMMON.EVENT')}`}},[_c('event-selector',{attrs:{"event":_vm.vehicle.event?.id,"required":""},on:{"eventChanged":(eventId) => {
            _vm.vehicle.event = {
              id: eventId,
              type: 'events',
            };
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.event_id}})],1):_vm._e(),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.SAVE"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }