var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"form",staticClass:"vehicle-form-inner",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.PICKUP_LOCATION')}`,"placeholder":`${_vm.$t('VEHICLES.PICKUP_LOCATION')}`}},[_c('addresses-selector',{attrs:{"address":_vm.vehicle.pickup_location},on:{"addressInputChangeChanged":(values) => {
            _vm.vehicle.pickup_location = values;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.pickup_location}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.AUCTION_TYPE')}`,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('AUCTIONS.AUCTION_TYPE')}`},model:{value:(_vm.vehicle.auction_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_type", $$v)},expression:"vehicle.auction_type"}},[_c('auction-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.auction_type},on:{"auctionTypeChanged":(type) => {
            _vm.vehicle.auction_type = type;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.auction_type}})],1),([_vm.AUCTION_TYPE_EXPRESS].includes(_vm.vehicle.auction_type))?_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EVENT')}`,"placeholder":`${_vm.$t('COMMON.EVENT')}`}},[_c('event-selector',{attrs:{"event":_vm.vehicle.event?.id,"required":""},on:{"eventChanged":(eventId) => {
            _vm.vehicle.event = {
              id: eventId,
              type: 'events',
            };
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.event_id}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.BUY_NOW_PRICE')}`,"placeholder":`${_vm.$t('VEHICLES.BUY_NOW_PRICE')}`,"disable":_vm.disable},model:{value:(_vm.vehicle.buy_now_price),callback:function ($$v) {_vm.$set(_vm.vehicle, "buy_now_price", $$v)},expression:"vehicle.buy_now_price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.buy_now_price}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.MIN_BID_AMOUNT')}`,"placeholder":`${_vm.$t('VEHICLES.MIN_BID_AMOUNT')}`,"disable":_vm.disable,"required":""},model:{value:(_vm.vehicle.min_bid_amount),callback:function ($$v) {_vm.$set(_vm.vehicle, "min_bid_amount", $$v)},expression:"vehicle.min_bid_amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.min_bid_amount}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.RESERVE_PRICE')}`,"placeholder":`${_vm.$t('VEHICLES.RESERVE_PRICE')}`,"disable":_vm.disable,"required":""},model:{value:(_vm.vehicle.reserve_price),callback:function ($$v) {_vm.$set(_vm.vehicle, "reserve_price", $$v)},expression:"vehicle.reserve_price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reserve_price}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }