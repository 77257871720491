<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="inspection-form-group width-1"
      v-if="
        [AUCTION_TYPE_EVALUATION, AUCTION_TYPE_TIMED].includes(
          vehicle.auction_type
        )
      "
    >
      <base-input
        :label="`${$t('VEHICLES.AUCTION_END_DATE')} (*)`"
        :placeholder="$t('VEHICLES.AUCTION_END_DATE')"
        v-model="vehicle.auction_end_date"
      >
        <flat-picker
          :placeholder="$t('VEHICLES.AUCTION_END_DATE')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            locale: $flatPickrLocale(),
            minDate: 'today',
          }"
          class="form-control datepicker"
          v-model="vehicle.auction_end_date"
        >
        </flat-picker>
      </base-input>

      <validation-error :errors="apiValidationErrors.auction_end_date" />
    </div>

    <div
      class="inspection-form-group width-1"
      v-if="[AUCTION_TYPE_EVALUATION].includes(vehicle.auction_type)"
    >
      <base-input
        :label="`${$t('VEHICLES.SALE_CONFIRMATION_DATE')} (*)`"
        :placeholder="$t('VEHICLES.SALE_CONFIRMATION_DATE')"
        v-model="vehicle.sale_confirmation_date"
      >
        <flat-picker
          :placeholder="$t('VEHICLES.SALE_CONFIRMATION_DATE')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            locale: $flatPickrLocale(),
            minDate: vehicle.auction_end_date,
            disable: [
              function (date) {
                return !vehicle.auction_end_date;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="vehicle.sale_confirmation_date"
        >
        </flat-picker>
      </base-input>

      <validation-error :errors="apiValidationErrors.sale_confirmation_date" />
    </div>

    <div
      v-if="[AUCTION_TYPE_EXPRESS].includes(vehicle.auction_type)"
      class="inspection-form-group width-1"
    >
      <base-input
        :label="`${$t('COMMON.EVENT')}`"
        :placeholder="`${$t('COMMON.EVENT')}`"
      >
        <event-selector
          :event="vehicle.event?.id"
          required
          @eventChanged="
            (eventId) => {
              vehicle.event = {
                id: eventId,
                type: 'events',
              };
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.event_id" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ $t("COMMON.SAVE") }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  AUCTION_TYPE_EVALUATION,
  AUCTION_TYPE_TIMED,
  AUCTION_TYPE_EXPRESS,
} from "@/constants/auctions";
import EventSelector from "@/components/EventSelector.vue";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    EventSelector,
  },

  mixins: [formMixin],

  props: ["vehicleData", "formErrors", "loading"],

  data() {
    let vehicleData = {
      ...this.vehicleData,
      auction_end_date: this.vehicleData.auction_end_date
        ? this.$timeZoneDateFormat(
            this.vehicleData.auction_end_date,
            "YYYY-MM-DD HH:mm"
          )
        : moment().add(1, "days").format("YYYY-MM-DD HH:mm"),
      sale_confirmation_date: this.vehicleData.sale_confirmation_date
        ? this.$timeZoneDateFormat(
            this.vehicleData.sale_confirmation_date,
            "YYYY-MM-DD HH:mm"
          )
        : this.vehicleData.sale_confirmation_date,
    };

    return {
      vehicle: vehicleData,
      AUCTION_TYPE_EVALUATION,
      AUCTION_TYPE_TIMED,
      AUCTION_TYPE_EXPRESS,
    };
  },

  created() {},

  mounted() {
    this.loadEvaluationAuctionDeadline();
  },

  methods: {
    async handleSubmit() {
      let vehicleData = cloneDeep(this.vehicle);

      this.$emit("vehicleSubmitted", {
        ...vehicleData,
        event_id: vehicleData.event?.id ?? null,
        auction_end_date: vehicleData.auction_end_date
          ? this.$convertDateToUtc(vehicleData.auction_end_date)
          : null,
        sale_confirmation_date: vehicleData.sale_confirmation_date
          ? this.$convertDateToUtc(vehicleData.sale_confirmation_date)
          : null,
      });
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async loadEvaluationAuctionDeadline() {
      if (this.vehicle.auction_type !== AUCTION_TYPE_EVALUATION) {
        return;
      }

      let deadline = 7;

      try {
        await this.$store.dispatch("auctions/list", {
          filter: {
            auction_type: this.vehicle.auction_type,
            status: "ongoing",
          },
          fields: { auctions: "sale_confirmation_deadline" },
          sort: "-created_at",
          page: {
            size: 1,
          },
        });

        const auctions = this.$store.getters["auctions/list"];

        if (!auctions.length) {
          return;
        }

        deadline = auctions[0].sale_confirmation_date;
      } catch (error) {}

      this.vehicle.sale_confirmation_date = moment(
        this.vehicle.auction_end_date
      )
        .add(deadline ?? 7, "days")
        .format("YYYY-MM-DD HH:mm");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = {
          ...this.vehicle,
          ...cloneDeep(vehicleData),
        };
      }
    },
  },
};
</script>
