<template>
  <div class="vehicle-form">
    <div class="steps-header">
      <el-steps :active="activeStep" finish-status="success">
        <el-step
          title="Etape 1"
          @click.native="navigateToStep(0)"
          :style="{
            cursor:
              vehicle.status === VEHICLE_STATUS_COMPLETED
                ? 'pointer'
                : 'initial',
          }"
        />
        <el-step
          title="Etape 2"
          @click.native="navigateToStep(1)"
          :style="{
            cursor:
              vehicle.status === VEHICLE_STATUS_COMPLETED
                ? 'pointer'
                : 'initial',
          }"
        />
        <el-step
          title="Etape 3"
          @click.native="navigateToStep(2)"
          :style="{
            cursor:
              vehicle.status === VEHICLE_STATUS_COMPLETED
                ? 'pointer'
                : 'initial',
          }"
        />
        <el-step
          title="Etape 4"
          @click.native="navigateToStep(3)"
          :style="{
            cursor:
              vehicle.status === VEHICLE_STATUS_COMPLETED
                ? 'pointer'
                : 'initial',
          }"
        />
      </el-steps>
    </div>

    <div class="steps-content">
      <div class="vehicle-form-title">
        <h3>{{ $t("VEHICLES.VEHICLE_IDENTIFICATION") }}</h3>
      </div>

      <component
        :is="currentStepComponent"
        ref="currentForm"
        :vehicle-data="vehicle"
        :form-errors="formErrors"
        :loading="loading"
        :disable="disable"
        @formChanged="
          (form) => {
            vehicle = { ...vehicle, ...form };

            onFormChanged();
          }
        "
        @submitted="handleSubmit"
      />
    </div>

    <div class="steps-footer">
      <el-button
        @click="prevStep"
        :loading="loading"
        :disable="disable || activeStep === 0"
        class="prev"
      >
        <i class="fal fa-arrow-left"></i>
        {{ $t("COMMON.PREVIOUS") }}
      </el-button>

      <el-button
        type="primary"
        @click="nextStep"
        :loading="loading"
        :disable="disable"
        class="next"
      >
        {{ activeStep === 3 ? saveButtonText : $t("COMMON.NEXT") }}
        <i class="fal fa-arrow-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import { Button, Step, Steps } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import Step1 from "@/components/Vehicle/Steps/Step1.vue";
import Step2 from "@/components/Vehicle/Steps/Step2.vue";
import Step3 from "@/components/Vehicle/Steps/Step3.vue";
import Step4 from "@/components/Vehicle/Steps/Step4.vue";
import { VEHICLE_STATUS_COMPLETED } from "@/constants/vehicles";

export default {
  layout: "DashboardLayout",

  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: { type: Object | Array, default: null },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    const vehicleData = cloneDeep(this.vehicleData);

    const nextTab =
      vehicleData.current_step === undefined ||
      vehicleData.status.toLowerCase() ===
        VEHICLE_STATUS_COMPLETED.toLowerCase()
        ? 0
        : vehicleData.current_step < 3
        ? (vehicleData.current_step ?? 0) + 1
        : 3;

    return {
      vehicle: vehicleData,
      vehicleBackup: vehicleData,
      activeStep: nextTab,
      VEHICLE_STATUS_COMPLETED,
    };
  },

  computed: {
    currentStepComponent() {
      return [Step1, Step2, Step3, Step4][this.activeStep] || Step1;
    },
    saveButtonText() {
      if (this.vehicle && this.vehicle.id && this.vehicle.id !== "undefined") {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      } else {
        return this.$t("VEHICLES.ADD_VEHICLE");
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    nextStep() {
      this.$refs.currentForm.submit();
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    async handleSubmit(stepData) {
      let vehicleData = cloneDeep(stepData);

      if (
        !this.$currentUserCan(this.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      ) {
        vehicleData = this.$fillUserOrganizationData(vehicleData);
      }

      this.$emit("vehicleSubmitted", vehicleData);
    },

    reset() {
      this.vehicle = { ...this.vehicle, ...this.vehicleBackup };
    },

    onFormChanged() {
      this.$emit("formChanged", this.vehicle);
    },

    navigateToStep(step) {
      if (this.vehicle.status === VEHICLE_STATUS_COMPLETED) {
        this.activeStep = step;
      }
    },
  },

  watch: {
    // "$route.query": {
    //   handler(query) {
    //     console.log("watch $route query", query);

    //     this.activeStep = parseInt(query.tab ?? "0") ?? 0;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData: {
      handler(vehicleData) {
        console.log("watch vehicle data", vehicleData);

        if (vehicleData) {
          this.vehicle = {
            ...this.vehicle,
            ...cloneDeep(vehicleData),
          };
        }
      },
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
